<!--
 * @Author: yuwenwen
 * @Date: 2022-11-15 16:07:01
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-22 20:24:45
 * @FilePath: \nc-funeral-mobile\src\views\index\companyIntroduce.vue
-->
<template>
  <div class="page-container page-container-100">
    <div class="policy">
      <div class="policy-article-content" v-html="htmlStr"></div>
      <!-- <img class="tel-icon" src="../../assets/images/tel-g-icon.png" > -->
    </div>
  </div>
</template>
<script>
import { getCompanyIntroduce } from "@/api/index.js";
export default {
  name: "companyIntroduce",
  data() {
    return {
        htmlStr:''
    };
  },
  created() {
    this.getCompanyIntroduce();
  },
  mounted() {},
  methods: {
    async getCompanyIntroduce() {
      let [err, res] = await this.$awaitWrap(getCompanyIntroduce());
      if (err) {
        return;
      }
      this.htmlStr = res.data;
      console.log(res);
    },
  },
};
</script>
<style lang="less" scoped>
.tel-icon{
    position: absolute;
    right: 20px;
    height: 200px;
    width: 30px;
    height: 30px
}
@import "../../assets/styles/index.less";
</style>